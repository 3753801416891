import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {FilePicker} from 'components/FilePicker/FilePicker';
import React, {useCallback} from 'react';
import block from 'bem-cn-lite';

import './DialogFilePicker.scss';

const b = block('dialog-file-picker');

type FilePickerProps = {
    value: File;
    onChange: (value: File) => void;
};

export function DialogFilePicker(props: FilePickerProps) {
    const onChange = useCallback(async (fileList: FileList | null) => {
        const file = fileList && fileList[0];

        if (file) {
            props.onChange(file);
        }
    }, []);

    let button;

    if (props.value) {
        button = (
            <>
                {props.value.name} <Button className={b('button')}>Choose file</Button>
            </>
        );
    } else {
        button = <Button>Choose file</Button>;
    }

    return <FilePicker onChange={onChange}>{button}</FilePicker>;
}

DialogFilePicker.getDefaultValue = () => {
    return {};
};

DialogFilePicker.renderLabel = (field: {caption: string}) => {
    if (field.caption) {
        return field.caption;
    }

    return null;
};
