import React from 'react';
import {
    TimeDuration,
    TimeDurationProps,
} from '@ytsaurus-ui-platform/src/ui/components/TimeDuration/TimeDuration';

type JupyterSecretsProps = {
    value: number;
    onChange: (value?: number) => void;
};

export function JupytDurationDialogControl({value, onChange}: JupyterSecretsProps) {
    const currentValue = React.useMemo(() => {
        return {
            value,
        };
    }, [value]);

    const handleChange = React.useCallback(
        (newValue: TimeDurationProps['value']) => {
            if (newValue) {
                onChange(newValue.value);
            }
        },
        [onChange],
    );

    return <TimeDuration value={currentValue} onChange={handleChange} />;
}

JupytDurationDialogControl.getDefaultValue = () => {
    return {};
};

JupytDurationDialogControl.renderLabel = (field: {caption: string}) => {
    if (field.caption) {
        return field.caption;
    }

    return null;
};
